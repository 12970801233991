import React, { useRef, useState } from 'react'
import { useFrame, useLoader } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import { TextureLoader } from 'three'

const Box = (props) => {
  // This reference will give us direct access to the mesh
  const meshRef = useRef()
  // Set up state for the hovered and active state
  const [ hovered, setHover ] = useState(false)
  const [ active, setActive ] = useState(false)

  const colorMap = useLoader(TextureLoader, 'icon-dark.png')
  const { scene } = useGLTF('logo.gltf')
  // Subscribe this component to the render-loop, rotate the mesh every frame
  // useFrame((state, delta) => {
  //   meshRef.current.rotation.y += delta
  //   scene.rotation.x = state.mouse.x * 0.08
  //   scene.rotation.y = state.mouse.y * 0.08
  // }, [])
  useFrame((state) => {
    // scene.getObjectByName('Plane').setRotationFromAxisAngle(new Vector3(1,1,1), state.mouse.x*1.2)
    // // scene.getObjectByName('Plane').rotation.x = state.mouse.x * 0.2
    // // scene.getObjectByName('Plane').rotation.y = state.mouse.y * 0.2
    // // scene.getObjectByName('Plane').rotation.z = state.mouse.y * 0.1
    // // scene.getObjectByName('Plane').rotation.x = state.mouse.x * 0.9
    scene.rotation.x = state.mouse.x * 1
    // scene.rotation.z = (state.mouse.x + state.mouse.y) * 0.3
    scene.rotation.y = state.mouse.y * 1
  }, [])



  return (
    <>
      <spotLight position={[ 10, 10, 10 ]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
      <pointLight position={[ -5, -5, -5 ]} decay={0} intensity={Math.PI} />
      <mesh
        {...props}
        ref={meshRef}
        scale={1}
        onClick={(event) => setActive(!active)}
        // onPointerEnter={(event)=>scene.rotateX(mouse)}
        onPointerOver={(event) => setHover(true)}
        onPointerOut={(event) => setHover(false)}
      >
        <primitive object={scene} />
        <meshStandardMaterial map={colorMap} />
      </mesh>
    </>
  )
}

export default Box