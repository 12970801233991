import './App.css'
import Box from './Box'
import { Canvas } from '@react-three/fiber'

function App() {
  return (
    <div className="vw-100 vh-100">
      <Canvas >
        <Box />
      </Canvas>
    </div>
  )
}

export default App
